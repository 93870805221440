@font-face {
  font-family: 'Monst_Bold';
  src: local('Monst_Bold'), url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Monst_Light';
  src: local('Monst_Light'), url(../fonts/Montserrat/Montserrat-Medium.ttf) format('opentype');
}

.imgBox {
  /* padding-top: 10px;   */
  margin-left: 20px;
  margin-right: 20px; 
  display: "block"; 
  width: "100%"; 
  overflow: "auto";
/*  */

}

.headshotImg {
  padding-bottom: 1em;

}

img {
  /* box-shadow: inset 0 0 50px 2px #282a2d;   */
  /* padding-bottom: 1em; */
}


#site-all {
  font-weight: black;
  margin-left: 5em;
  margin-right: 5em;
  /* padding-top: 0.5em; */
}

/* //navigationsleiste  */
.navPad {
  padding-bottom: 1em;
  font-family: Monst_Light, Arial, serif;


}

.headBold {
  font-family: Monst_Bold, Arial, serif;
}

.infoText {
  font-family: Monst_Light, Arial, serif;
}



/* Handy !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
@media only screen and (max-width: 800px) {


  #site-all {
    width: 100%;
    font-size: 1em;
    margin: 0.5em; 
    /* margin-right: 0em;
    padding-top: 0em; */
  }

  .navPad {
    /* padding-left: 10em; */
    padding-bottom: 1em;
    /* padding-top: 0.5em; */
  }


    /* Bildergalerie; */

  .imgBox {

    padding-top: 5px;
    /* margin-left: 1em;  */
    /* margin-right: 1em;  */
     display: "block"; 
    width: "100%"; 
     overflow: "auto"; 
  }

   /* // zentriert am handy das bild  */
  .headshotImg {
    padding-bottom: 1em;
    display: block;
    margin-left: auto;
    margin-right: auto;
  
  }
}




@media (min-width:801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
  /* hi-res laptops and desktops */
}